import React, {createContext, useEffect, useReducer} from 'react';

// third-party
import {Auth0Client} from '@auth0/auth0-spa-js';

// reducer - state management
import {LOGIN, LOGOUT} from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import {AUTH0_API} from 'config';
import {KeyedObject} from 'types/root';
import {Auth0ContextType, AuthProps, UserProfile} from 'types/auth';

// constant
let auth0Client: Auth0Client;

const initialState: AuthProps = {
    isLoggedIn: false,
    token: "",
    isInitialized: false,
    user: null
};
  
// ==============================|| AUTH0 CONTEXT & PROVIDER ||============================== //

const Auth0Context = createContext<Auth0ContextType | null>(null);

const getDefaultAvatar = (email: string) => {
    return `https://ui-avatars.com/api/?name=${encodeURIComponent(email)}&background=random`;
};

export const Auth0Provider = ({children}: { children: React.ReactElement }) => {
        const [state, dispatch] = useReducer(authReducer, initialState);

        const login = async (options?: KeyedObject) => {
            try {
                await auth0Client.loginWithRedirect({
                    redirect_uri: window.location.origin,
                    appState: { returnTo: '/dashboard/analytics' }
                });
            } catch (err) {
                console.error('Auth0 login error:', err);
            }
        };

        useEffect(() => {
            const init = async () => {
                try {
                    auth0Client = new Auth0Client({
                        domain: AUTH0_API.domain,
                        client_id: AUTH0_API.client_id,
                        redirect_uri: window.location.origin,
                        audience: AUTH0_API.audience,
                        scope: AUTH0_API.scope,
                        useRefreshTokens: AUTH0_API.useRefreshTokens,
                        cacheLocation: 'localstorage'
                    });

                    // Handle the redirect callback
                    if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
                        try {
                            const result = await auth0Client.handleRedirectCallback();
                            const targetUrl = result?.appState?.returnTo || '/dashboard/analytics';
                            window.location.href = window.location.origin + targetUrl;
                            return;
                        } catch (callbackError) {
                            console.error('Callback handling error:', callbackError);
                            window.location.href = window.location.origin;
                        }
                    }

                    try {
                        await auth0Client.checkSession();
                        const isLoggedIn = await auth0Client.isAuthenticated();

                        if (isLoggedIn) {
                            const user = await auth0Client.getUser();
                            const token = await auth0Client.getTokenSilently();
                            let namespace = "https://api.versaquant.com";
                            
                            const userProfile: UserProfile = {
                                id: user?.sub,
                                email: user?.email,
                                name: user?.name || user?.email?.split('@')[0],
                                app_metadata: user?.[`${namespace}/app_metadata`] || {},
                                picture: user?.picture || getDefaultAvatar(user?.email || ''),
                                role: user?.['https://api.versaquant.com/roles']?.[0] || 'user'
                            };

                            dispatch({
                                type: LOGIN,
                                payload: {
                                    isLoggedIn: true,
                                    token: token,
                                    user: userProfile
                                }
                            });

                            // If we're on the root path and logged in, redirect to dashboard
                            if (window.location.pathname === '/') {
                                window.location.href = window.location.origin + '/dashboard/analytics';
                            }
                        } else {
                            dispatch({
                                type: LOGOUT
                            });
                        }
                    } catch (sessionError) {
                        console.error('Session check error:', sessionError);
                        dispatch({
                            type: LOGOUT
                        });
                    }
                } catch (err) {
                    console.error('Auth0 initialization error:', err);
                    dispatch({
                        type: LOGOUT
                    });
                }
            };

            init();
        }, []);

        const logout = async () => {
            try {
                // First dispatch logout to update app state
                dispatch({
                    type: LOGOUT
                });

                // Perform complete Auth0 logout
                await auth0Client.logout({
                    client_id: AUTH0_API.client_id,
                    returnTo: window.location.origin,
                    federated: true
                });

                // Force reload the page to clear any remaining state
                window.location.replace(window.location.origin);
            } catch (err) {
                console.error('Logout error:', err);
                // Force reload as fallback
                window.location.replace(window.location.origin);
            }
        };

        const resetPassword = async (email: string) => {
        };

        const updateProfile = () => {
        };


        if (state.isInitialized !== undefined && !state.isInitialized) {
            return <Loader/>;
        }

        return <Auth0Context.Provider
            value={{...state, login, logout, resetPassword, updateProfile}}>{children}</Auth0Context.Provider>;
    }
;

export default Auth0Context;
